/* .App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */




/*====================================
STYLES & VARS
=====================================*/

:root {
    --primary-color-p100: #1A000B;
    --primary-color-p200: #2E0015;
    --primary-color-p300: #420021;
    --primary-color-p400: #620031;
    --primary-color-p500: #87054A;
    --primary-color-p600: #A30664;
    --primary-color-p700: #C10570;
    --primary-color-p800: #E4087E;
    --primary-color-p900: #F6098B;
    --primary-color-p1000: #F934A7;
    --primary-color-p1100: #FF61C5;
    --primary-color-p1200: #FFADE2;
    --primary-color-p1300: #FFD1F0;
    --primary-color-p1400: #FFEBF8;
    --primary-color-p1500: #FFFAFD;
    --neutral-color-n100: #331F29;
    --neutral-color-n200: #4B323F;
    --neutral-color-n300: #593E4C;
    --neutral-color-n400: #6D5261;
    --neutral-color-n500: #7C6170;
    --neutral-color-n600: #947B8A;
    --neutral-color-n700: #B19AA9;
    --neutral-color-n800: #D9CBD5;
    --neutral-color-n900: #EBE4E9;
    --neutral-color-n1000: #FAF5F9;
}

.graident--primary-icon {
    background-color: #DC097A;
    background-image: linear-gradient(180deg, #DC097A 0%, #FF018D 100%);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.gradient--primary {
    background: linear-gradient(180deg, #DC097A 0%, #FF018D 100%);
}

.gradient--btn-primary {
    background: linear-gradient(90deg, #DC097A 0%, #FF018D 100%);
}

a.gradient--btn-primary:hover {
    background: linear-gradient(90deg, #DC097A 40%, #FF018D 120%);
    transition: 0.35s;
}

.gradient--hero-cream {
    background: linear-gradient(90deg, #E5C1A8 0%, #FDEAD9 50%, #FFF2E2 100%);
}

.gradient--strawberry-cream-1 {
    background: linear-gradient(180deg, #FFF -45.02%, #FFF 3.32%, #FFFAFD 42.72%, #FFEBF8 84.05%, #FFE0F4 98.55%);
}

.gradient--strawberry-cream-2 {
    background: linear-gradient(180deg, #FFE0F4 -7.1%, #FFEBF8 4.04%, #FFF2FA 29.87%, #FFFAFD 60.76%, #FFF 82.54%, #FFF 93.17%);
}

.gradient--dark-cherry-nft {
    background: linear-gradient(180deg, #19000B 7%, #140109 100%);
    background: linear-gradient(180deg, #140008 7%, #0F0106 100%);
}

.gradient--dapp-cream {
    background: linear-gradient(180deg, #FFF3E2 24.5%, rgba(229, 193, 168, 0.74) 100%);
}

.gradient--silver-cream-everyone {
    background: linear-gradient(180deg, #FAF9FA 46%, #F6F3F5 100%);
}

.gradient--about-creamy-pink-white {
    background: linear-gradient(180deg, #FFF 0%, #FAF5F9 48%, #FFF 100%);
}



/*====================================
NORMALIZE
=====================================*/

html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

/*====================================
TYPOGRAPHY
=====================================*/

/*
font-family: 'Kodchasan', sans-serif;
font-family: 'Roboto', sans-serif;
*/

.App {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
}

/*====================================
LAYOUT
=====================================*/

.layout-container {
    width: 100%;
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto;
}

.layout-container-large {
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.layout-hpad {
    padding-left: 36px;
    padding-right: 36px;
}

@media (max-width: 1067px) {
    .layout-hpad {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media (max-width: 387px) {
    .layout-hpad {
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media (max-width: 377px) {
    .layout-hpad {
        padding-left: 8px;
        padding-right: 8px;
    }
}

/*====================================
BUTTONS
=====================================*/

.btn {
    display: inline-block;
    padding: 24px 48px;
    padding: 20px 28px;
    line-height: 1;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    border-radius: 500em;
}

.btn i {
    font-size: 13px;
    margin-left: 3px;
}

.btn-alt {
    display: inline-block;
    background-color: rgba(46,0,21,0.05);
    color: var(--neutral-color-n300);
    padding: 16px 20px;
    border-radius: 50px;
    line-height: 1;
    text-decoration: none;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
}

a.btn-alt:hover {
    background-color: rgba(46,0,21,0.085);
    transition: 0.25s;
}

.btn-alt--large {
    padding: 18px 30px;
    font-size: 16px;
}

.btn-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

@media (max-width: 1067px) {
    .btn-group {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.btn-group > a {
    width: 260px;
    text-align: center;
}

@media (max-width: 1067px) {
    .btn-group > a {
        width: 260px;
    }
}

.btn-group > a:nth-child(2) {
    background: initial;
    background-color: rgba(0,0,0,0) !important;
    border: 2px solid #f50288;
    color: #f50288;
}


/*====================================
SECTION BACKGROUND
=====================================*/

.section-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.section-bg-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
}

.section-bg img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    opacity: 0.1;
    display: none;
}

.section-wrapper {
    position: relative;
}

.section-bg-layer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.section-bg-layer.under {
    background: linear-gradient(90deg, #4E4E4E 3.65%, #B4B4B4 100%);
    display: none;
}

.section-bg-layer.over {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 20.36%, #FFF 100%);
}


/*====================================
ELEMENT - CONTENT GRID
=====================================*/

.content-grid {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 48px;
}

@media (max-width: 1067px) {
    .content-grid {
        flex-direction: column;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 36px;
    }
}

.content-grid-item {
    /* width: calc(33.333333% - 10px); */
    flex: 1 0 calc(33.333333% - 32px);
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--neutral-color-n900);
    background: #FFF;
    box-shadow: 0px 2px 1px 0px var(--neutral-color-n900);
    /* margin-bottom: 16px; */
}

.content-grid--dark .content-grid-item {
    background-color: #1C0D15;
    border-color: var(--neutral-color-n100);
    box-shadow: 0px 4px 1px 0px #111111;
    box-shadow: none;
}

.content-grid-item-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

@media (max-width: 1067px) {
    #token .content-grid-item-heading {
        justify-content: center;
    }
    #token .content-grid-item-body > p {
        text-align: center;
    }
}

.content-grid-item-heading img {
    width: auto;
    height: 18px;
    display: block;
}

.content-grid-item-heading h3 {
    margin: 0;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 600;
    color: var(--primary-color-p200);
}

.content-grid--dark .content-grid-item-heading h3 {
    color: var(--primary-color-p1500);
}

.content-grid-item-body {
    text-align: left;
}

.content-grid-item-body p {
    display: block;
    margin-top: 0;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 400;
    color: var(--neutral-color-n300);
}

.content-grid-item--has-note .content-grid-item-body > p:nth-child(1) {
    margin-bottom: 0;
}

@media  (max-width: 1067px) {
    .content-grid-item--has-note .content-grid-item-body > p:nth-child(1) {
        margin-bottom: 0;
    }
}

.content-grid-item-body > p:nth-child(2) {
    font-size: 10px;
    font-style: italic;
    opacity: 0.4;
    margin-bottom: 0;
}

.content-grid--dark .content-grid-item-body p {
    color: var(--neutral-color-n800);
}

.content-grid-item-body p:last-child {
    margin-bottom: 0;
}

/* LARGE */

.content-grid--large {}

.content-grid--large .content-grid-item {
    padding: 60px 30px;
}

.content-grid--large .content-grid-item-heading img {
    height: 82px;
    width: auto;
}

.content-grid--large .content-grid-item-heading h3 {
    font-size: 28px;
    font-size: 36px;
    line-height: 1.1;
    color: #222222;
}

.content-grid--large .content-grid-item-body p {
    font-size: 18px;
    line-height: 1.44;
}

/*====================================
MISC
=====================================*/

.social-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    line-height: 1;
}

@media (max-width: 1067px) {
    .social-icons {
        justify-content: center;
    }
}

.social-icons > a {
    display: block;
    font-size: 32px;
    color: var(--primary-color-p200);
    transition: 0.05s;
}

@media (max-width: 1067px) {
    .social-icons > a {
        font-size: 32px;
    }
}

.social-icons > a:hover {
    color: var(--primary-color-p600);
    transition: 0.25s;
}



/*====================================
HEADER & SITE NAV
=====================================*/

header {
    position: fixed;
    position: absolute;
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

@media (max-width: 1067px) {
    header {
        height: 60px;
    }
}

/*====================================
TOP BAR
=====================================*/

.App-topbar {
    width: 100%;
    height: 32px;
    background: linear-gradient(90deg, #FFF 0%, #FFE1F5 50%, #FFF 100%);
}

.App-topbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
}

.App-topbar-container > a {
    font-size: 14px;
    line-height: 1;
    text-decoration: none;
    color: #2E0015;
    position: relative;
}

@media (max-width: 467px) {
    .App-topbar-container > a {
        font-size: 12px;
    }
}

.App-topbar-container > a > span > img {
    height: 12px;
    display: inline-block;
    width: auto;
    margin-right: 8px;
    margin-left: 6px;
}

@media (max-width: 467px) {
    .App-topbar-container > a > span > img {
        height: 10px;
        margin-left: 4px;
        margin-right: 6px;
    }
}

.App-topbar-container > a > span:nth-child(1) {
    display: inline-block;
}

.App-topbar-container > a > span:nth-child(2) {
    display: none;   
}

@media (max-width: 467px) {
    .App-topbar-container > a > span:nth-child(1) {
        display: none;
    }
    
    .App-topbar-container > a > span:nth-child(2) {
        display: inline-block;;   
    }
}

.App-topbar-container > i {
    position: absolute;
    right: 12px;
    font-size: 12px;
}


.App-topbar-container > i:hover {
    cursor: pointer;
}

.App-topbar.hideTopBar {
    display: none;
}

/*====================================
HEADER & SITE NAV (MOBILE)
=====================================*/

.App-header-mobile {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 967px) {
    .App-header-mobile {
        display: flex;
    }
}

/* Logo */

.header-mobile-logo > a,
.site-nav-mobile-logo > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 3.25px;
    text-decoration: none;
    font-family: 'Kodchasan', sans-serif;
}

.header-mobile-logo > a > img,
.site-nav-mobile-logo > a > img {
    display: block;
    max-width: 25px;
}

.header-mobile-logo > a > span,
.site-nav-mobile-logo > a > span {
    font-size: 20px;
    font-weight: 700;
    color: var(--primary-color-p200);
}

.site-nav-mobile-logo > a > span {
    color: #FFFFFF;
}

.header-mobile-logo > a > span > span:last-child,
.site-nav-mobile-logo > a > span > span:last-child {
    font-weight: 200;
}

/* Nav Button */

.header-mobile-nav {
    flex: 1;
    text-align: right;
    margin-right: 16px;
    margin-right: 0;
}

.header-mobile-nav > button {
    /* background-color: #FFFFFF;
    height: 32px;
    width: 32px;
    border-radius: 12px; */
    background-color: rgba(0,0,0,0);
    border: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    font-size: 24px;
    margin-right: 8px;
    color: var(--primary-color-p200);
}

@media (max-width: 567px) {
    .header-mobile-nav > button {
    }
}

.header-mobile-nav > button:hover {
    cursor: pointer;
}

/* Nav Panel */

nav.site-nav-mobile {
    width: 82vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #000000;
    padding: 16px 48px;
    text-align: left;
    transition: 0.5s;
    transform: translateX(-120vw);
    border-right: 1px solid #EC1B8E;
    border-right: none;
}

nav.site-nav-mobile.Reveal {
    transition: 0.5s;
    transform: translateX(0);
}

nav.site-nav-mobile > ul {
    position: relative;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
}

.site-nav-mobile-logo > a {
    justify-content: flex-start;
    flex-direction: row;
}

.site-nav-mobile-logo {
    margin-top: 32px;
    margin-bottom: 32px;
}

.site-nav-mobile-button {
    margin-top: 32px;
    margin-top: 48px;
    margin-top: auto;
    margin-bottom: 48px;
}

.site-nav-mobile-item > a {
    text-decoration: none;
    color: #FFFFFF;
    font-size: 18px;
    width: 100%;
    padding: 6px 0;
    display: block;
}

.site-nav-mobile-close {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
}

.site-nav-mobile-close > button {
    outline: 0;
    border: 0;
    border-radius: 12px;
    height: 32px;
    width: 32px;
    background-color: rgba(0,0,0,0);
    color: #FFFFFF;
}

.site-nav-mobile-close > button:hover {
    cursor: pointer;
}

/* Mobile Main Button */

.header-mobile-button {
    display: none;
}

.header-mobile-button > a {
    font-size: 14px;
    background-color: #EC1B8E;
    padding: 8px 16px;
    border-radius: 12px;
    color: #FFFFFF;
    text-decoration: none;
}

/*====================================
HEADER & SITE NAV (DESKTOP)
=====================================*/

.App-header-desktop {
    display: block;
}

@media (max-width: 967px) {
    .App-header-desktop {
        display: none;
    }
}

.site-nav-desktop > ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Site Nav Logo */

.site-nav-desktop-logo {
}

.site-nav-desktop-logo > a {
    font-family: 'Kodchasan', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4.6px;
    text-decoration: none;
    color: var(--primary-color-p200);
}

.site-nav-desktop-logo > a > img {
    width: 100%;
    display: block;
    max-width: 36px;
}

.site-nav-desktop-logo > a > span {
    font-size: 28px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: -0.03em;
}

.site-nav-desktop-logo > a > span > span:last-child {
    font-weight: 200;
}

/* Site Nav Menu Items */

.site-nav-desktop-item {
    font-size: 14px;
    line-height: 1;
    display: block;
}

.site-nav-desktop-item > a {
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    padding: 8px 16px;
    color: var(--primary-color-p200);
    text-decoration: none;
    display: block;
}

/* Site Nav Button */

.site-nav-desktop-button {
    text-align: right;
}

.site-nav-desktop-button > a {
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    padding: 16px 20px;
    border-radius: 500px;
    color: var(--primary-color-p1500);
    text-decoration: none;
    position: relative;
}

.site-nav-desktop-button > a > i {
    font-size: 12px;
    margin-left: 2px;
}

/* Main Nav */

.site-nav-desktop-main {
    background-color: #FFFFFF;
    padding: 0 16px;
    border-radius: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.site-nav-desktop-main > li > a {
    padding: 16px;
}

.site-nav-desktop-main > li {
    position: relative;
}

.site-nav-desktop-main > li:after {
    content: '';
    height: 12px;
    width: 1px;
    background-color: rgba(46,0,21,0.05);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.site-nav-desktop-main > li:last-child:after {
    content: none;
}

/*====================================
SECTION > HOME HERO
=====================================*/

.Section-Hero {
    padding-top: 88px;
    width: 100%;
    position: relative;
}

@media (max-width: 1067px) {
    .Section-Hero {
        padding-top: 60px;
    }
}

.hero-flexbox {
    padding-top: 96px;
    padding-bottom: 192px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 1067px) {
    .hero-flexbox {
        flex-direction: column;
        padding-top: 24px;
        padding-bottom: 162px;
    }
}

@media (max-width: 767px) {
    .hero-flexbox {
        padding-bottom: 106px;
    }
}


.hero-flexbox > div:nth-child(1) {
   flex:1;
   min-width: 780px;
}

@media (max-width: 1067px) {
    .hero-flexbox > div:nth-child(1) {
        min-width: initial;
        text-align: center;
     }
}

.hero-flexbox > div:nth-child(2) {
    padding-left: 5%;
    flex: 1;
}

@media (max-width: 1067px) {
    .hero-flexbox > div:nth-child(2) {
        display: none;
    }
}

.hero-flexbox > div:nth-child(2) > img {
    display: block;
    max-width: 360px;
}

@media (max-width: 767px) {
    .hero-flexbox > div:nth-child(2) > img {
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 567px) {
    .hero-flexbox > div:nth-child(2) > img {
        max-width: 216px;
    }
}

.Section-Hero h1 {
    font-size: 88px;
    line-height: 1.1;
    font-weight: 600;
    color: var(--primary-color-p200);
    margin: 0;
    margin-bottom: 24px;
}

@media (max-width: 1067px) {
    .Section-Hero h1 {
        font-size: 40px;
        text-align: center;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 16px;
    }
}

.Section-Hero p.sub-title {
    font-size: 24px;
    line-height: 1.1;
    font-weight: 500;
    color: var(--primary-color-p200);
    margin: 0;
    margin-bottom: 60px;
    letter-spacing: normal;
    max-width: 660px;
    opacity: 0.5;
}

@media (max-width: 1067px) {
    .Section-Hero p.sub-title {
        font-size: 20px;
        text-align: center;
        max-width: 360px;
        margin-bottom: 36px;
        margin-left: auto;
        margin-right: auto;
    }
}

.Section-Hero h1 > span {
    color: var(--primary-color-p800);
    font-family: 'Kodchasan', sans-serif;
    font-weight: bold;
}

.Section-Hero .social-icons {
    margin-bottom: 24px;
}

@media (max-width: 1067px) {
    .Section-Hero .social-icons {
        margin-bottom: 16px;
    }
}

@media (max-width: 767px) {
    .Section-Hero .social-icons {
        justify-content: center;
    }
}

@media (max-width: 567px) {
    .Section-Hero .social-icons {
        margin-bottom: 24px;
    }
}

.hero-flexbox > div:nth-child(2) > img {
    width: 100%;
    display: block;
}

.Section-Hero svg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 240px;
}

@media (max-width: 1067px) {
    .Section-Hero svg {
        height: 81px !important;
        height: 106px !important
    }
}

/* Hero Btn */

.Section-Hero .btn {}

@media (max-width: 567px) {
    .Section-Hero .btn {
        font-size: 16px;
        padding: 16px 32px;
        line-height: 1.4;
    }
}

.Section-Hero .layout-container {
    max-width: 1280px;
}   

/* Hero Mochi Token Mobile */

.hero-mochi-token-mobile {
    display: none;
}

.hero-mochi-token-mobile > img {
    width: 100%;
    display: block;
}

@media (max-width: 1067px) {
    .hero-mochi-token-mobile {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 180px;
        margin-bottom: 36px;
    }
}

/*====================================
SECTION > STATS
=====================================*/

.Section-Stats {
    padding-top: 120px;
    padding-bottom: 192px;
    position: relative;
}

@media (max-width: 1067px) {
    .Section-Stats {
        padding-top: 48px;
        padding-bottom: 72px;
        padding-bottom: 144px;
    }
}


.Section-Stats .layout-container > h2 {
    color: var(--primary-color-p200);
    margin: 0;
    font-size: 32px;
    line-height: 1.1;
    max-width: 716px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: 600;
    margin-bottom: 48px;
}

@media (max-width: 1067px) {
    .Section-Stats .layout-container > h2 {
        font-size: 24px;
        margin-bottom: 36px;
    }
}

.Section-Stats .layout-container > h2 > span {
    color: var(--primary-color-p900);
}

.stats-overview-flexbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1067px) {
    .stats-overview-flexbox {
        flex-direction: column;
        flex-direction: row;
        align-items: center;
        text-align: center;
        gap: 0;
        flex-wrap: wrap;
        max-width: 260px;
    }
}

.akljebg {
    display: none;
}

.stats-overview-item {
}

@media (max-width: 1067px) {
    .stats-overview-item {
        width: 50%;
        margin-bottom: 24px;
    }
}


.stats-overview-item > div:nth-child(1) {
    color: var(--primary-color-p200);
    font-size: 40px;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 4px;
}

@media (max-width: 1067px) {
    .stats-overview-item > div:nth-child(1) {
        font-size: 32px;
        margin-bottom: 4px;
    }
}

.stats-overview-item > div:nth-child(2) {
    color: var(--neutral-color-n600);
    font-size: 16px;
    line-height: 1.2;
    font-weight: 600;
    /* text-transform: uppercase; */

}

@media (max-width: 567px) {
    .stats-overview-item > div:nth-child(2) {
        font-size: 14px;
    }
}

.Section-Stats .svgContainer svg {
    display: block;

}

.Section-Stats .svgContainer svg path {
    fill: #FFFFFF !important;
}

.Section-Stats .svgContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}


/*====================================
SECTION > ABOUT
=====================================*/

.Section-About {
    padding-top: 0;
    padding-bottom: 72px;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #d5d5d5;
    border-bottom: none;
}

.Section-About .section-wrapper {
    padding-top: 60px;
}

.Section-About .about-header > img {
    width: 72px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
}

@media (max-width: 567px) {
    .Section-About .about-header > img {
        width: 48px;
    }
}

.Section-About .about-header > h2 {
    color: var(--primary-color-p200);
    margin: 0;
    margin-bottom: 48px;
    display: block;
    font-size: 40px;
    line-height: 1.1;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-transform: initial;
}

@media (max-width: 1067px) {
    .Section-About .about-header > h2 {
        font-size: 32px;
        margin-bottom: 48px;
    }
}

.Section-About .about-header > h2 > span {
    color: var(--primary-color-p900);
}

/* About Flexbox */

.about-flexbox {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 120px;
}

@media (max-width: 1067px) {
    .about-flexbox {
        flex-direction: column;
        gap: 0;
    }
}

.about-flexbox > div {
    width: 50%;
}

@media (max-width: 1067px) {
    .about-flexbox > div {
        width: 100%;
    }
}

.about-flexbox > div:nth-child(2) {}

@media (max-width: 1067px) {
    .about-flexbox > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }
}

.about-column {}

.about-item {
    margin-bottom: 32px;
    background-color: #FFFFFF;
    padding: 36px;
    border-radius: 32px;
    border: 1px solid var(--neutral-color-n900);
    box-shadow: 0px 3px 1px 0px var(--neutral-color-n900);
}

.about-item:last-child {
    margin-bottom: 0;
}

.about-item-heading .about-icon {
    margin-right: 24px;
}

@media (max-width: 1067px) {
    .about-item-heading .about-icon {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 36px;
    }
}

.about-item-heading .about-icon i {
    font-size: 64px;
    line-height: 64px;
}


.about-item-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-bottom: 16px;
}

@media (max-width: 1067px) {
    .about-item-heading {
        flex-direction: column;
        align-items: flex-start;
    }
}

.about-item-heading img {
    width: 48px;
    display: block;
    margin-right: 24px;
}

.about-item-heading h3 {
    margin: 0;
    color: var(--primary-color-p300);
    font-size: 32px;
    line-height: 1.1;
    font-weight: 600;
}

@media (max-width: 1067px) {
    .about-item-heading h3 {
        font-size: 20px;
        width: 100%;
    }
}

.about-item p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--neutral-color-n300);
    display: block;
    margin-bottom: 36px;
}


.about-item p:last-child {
    margin-bottom: 0 !important;
}

/* .about-item a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}

.about-item a > span {
    display: block;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: #afafaf;
    color: #aa9393;
    color: #9d8e8e;
    color: #b9abab;
    color: #503b3b;
    color: #770038;
    color: #500a28;
    color: #5a4949;
    color: #ccb4b4;
}

.about-item a > i {
    font-size: 14px;
    line-height: 18px;
    color: #a3a3a3;
    color: #aa9393;
    color: #9d8e8e;
    color: #b9abab;
    color: #503b3b;
    color: #770038;
    color: #ef00af;
    color: #5a4949;
    color: #ccb4b4;
    margin-top: 1px;
} */


.about-column--mbot64s {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
}

@media (max-width: 1067px) {
    .about-column--mbot64s {
        flex-direction: column;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
    }
}

.about-column--mbot64s .about-item {
    margin: 0;
    flex: 1 0 calc(50% - 16px);
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.about-column--mbot64s .about-item-heading {
    flex-direction: column;
    gap: 36px;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
}

.about-column--mbot64s .about-item-heading img {
    height: 64px;
    width: auto;
}

@media (max-width: 1067px) {
    .about-column--mbot64s .about-item-heading img {
        height: auto;
        width: 64px;
        display: block;
    }
}


.about-column--mbot64s .about-item p {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
    color: var(--neutral-color-n300);
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}


.about-column--mbot64s  .about-item p:last-of-type {
    margin-bottom: 36px;
}

.about-column--mbot64s  .about-item > *:last-child {
}

/*====================================
SECTION > ASTRO
=====================================*/


.astro {
    /* position: relative; */
}

@media (max-width: 1067px) {
    .astro {
        height: 390px;
        width: 100%;
    }
}

.astro > div {
    position: absolute;
}

.astro > div > img {
    display: block;
    width: 100%;
}

.astro-star1 {
    width: 24px;
    top: calc(220px + 5px);
    left: calc(((100vw - 1200px) / 2) + 230px);
}

@media (max-width: 1067px) {
    .astro-star1 {
        width: 13px;
        top: 10px;
        left: 14%;
        margin-left: auto;
        margin-right: auto;
    }
}


@media (prefers-reduced-motion: no-preference) {
    .astro-star1 {
        animation: astroStar1Grow infinite 1.05s step-end;
    }
}

@keyframes astroStar1Grow {
    0%, 100% {
        transform: scale(0.70);
    }
    50% {
        transform: scale(1.05);
    }
}

.astro-star2 {
    width: 22px;
    top: calc(220px + 109px);
    left: calc(((100vw - 1200px) / 2) + 170px);
}

@media (max-width: 1067px) {
    .astro-star2 {
        width: 12px;
        top: 28px;
        left: 11%;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .astro-star2 {
        animation: astroStar2Grow infinite 1.2s step-end;
    }
}

@keyframes astroStar2Grow {
    0%, 100% {
        transform: scale(0.70);
    }
    50% {
        transform: scale(1.05);
    }
}

.astro-sunsphere {
    width: 360px;
    top: calc(220px + 344px);
    left: calc(((100vw - 1200px) / 2) + 45px);
}

@media (max-width: 1067px) {
    .astro-sunsphere {
        width: 274px;
        width: 360px;
        top: 245px;
        left: 0;
        left: -45%;
        right: 18%;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .astro-sunsphere {
        animation: astroSunSphereMovement infinite 18s ease-in-out;
    }
}

@keyframes astroSunSphereMovement {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(20px);
    }
}

.astro-mochi-strawberry {
    width: 38px;
    top: calc(220px + 189px);
    left: calc(((100vw - 1200px) / 2) + 320px);
}

@media (max-width: 1067px) {
    .astro-mochi-strawberry {
        width: 19px;
        top: 180px;
        left: 40%;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .astro-mochi-strawberry {
        animation: astroMochiStrawberryMovement infinite 6s ease-in-out;
    }
}

@keyframes astroMochiStrawberryMovement {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.astro-saturn {
    width: 72px;
    top: calc(220px + 259px);
    left: calc(((100vw - 1200px) / 2) + 400px);
}

@media (max-width: 1067px) {
    .astro-saturn {
        width: 48px;
        left: 59%;
        top: 185px;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .astro-saturn {
        animation: astroSaturnMovement infinite 6s ease-in-out;
        animation-delay: 1.66s
    }
}

@keyframes astroSaturnMovement {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.astro-mochi-chocolate {
    width: 104px;
    top: calc(220px + 369px);
    left: calc(((100vw - 1200px) / 2) + 430px);
}

@media (max-width: 1067px) {
    .astro-mochi-chocolate {
        width: 90px;
        top: 240px;
        left: 75%;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .astro-mochi-chocolate {
        animation: astroMochiChocolateMovement infinite 6s ease-in-out;
        animation-delay: 3.32s
    }
}

@keyframes astroMochiChocolateMovement {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-12px);
    }
}


.astro-asteroid1 {
    display: none;
    width: 16px;
    top: 0;
    left: calc(((100vw - 1200px) / 2) + 80px);
}

@media (prefers-reduced-motion: no-preference) {
    .astro-asteroid1 {
        animation: asteroid1Movement infinite 12s linear;
    }
    .astro-asteroid1 > img {
        animation: asteroid1Spin infinite 3s linear;
    }
}

@keyframes asteroid1Movement {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(1500px);
    }
}

@keyframes asteroid1Spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.astro-asteroid2 {
    display: none;
    width: 37.5px;
    top: calc(220px + 349px);
    left: calc(((100vw - 1200px) / 2) + 20px);
}

@media (prefers-reduced-motion: no-preference) {
    .astro-asteroid2 {
        animation: asteroid2Movement infinite 11s ease-in-out;
        animation-delay: 0.8s;
    }
    .astro-asteroid2 > img {
        animation: asteroid2Spin infinite 18s linear;
    }
}


@keyframes asteroid2Movement {
    0%, 100% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
}

@keyframes asteroid2Spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.astro-asteroid3 {
    width: 13.5px;
    top: calc(226px + 30px);
    left: calc(((100vw - 1200px) / 2) + 410px);
}

@media (max-width: 1067px) {
    .astro-asteroid3 {
        width: 9.5px;
        left: 60%;
        display: none;
    }
}


@media (prefers-reduced-motion: no-preference) {
    .astro-asteroid3 {
        animation: asteroid3Movement infinite 56s linear,  asteroid3Fade infinite 56s ease-in;
    }
    .astro-asteroid3 > img {
        animation: asteroid3Spin infinite 0.8s linear;
    }
}

@keyframes asteroid3Movement {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(800px);
    }
}

@keyframes asteroid3Fade {
    0%, 10%, 90%, 100% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
}

@keyframes asteroid3Spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.astro-asteroid4 {
    width: 30px;
    top: calc(220px + 30px);
    left: calc(((100vw - 1200px) / 2) + 365px);
}

@media (max-width: 1067px) {
    .astro-asteroid4 {
        width: 22px;
        left: 22%;
        display: none;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .astro-asteroid4 {
        animation: asteroid4Movement infinite 80s linear,  asteroid4Fade infinite 80s ease-in;
    }
    .astro-asteroid4 > img {
        animation: asteroid4Spin infinite 5.5s linear;
    }
}

@keyframes asteroid4Movement {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(800px);
    }
}

@keyframes asteroid4Fade {
    0%, 100% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
}


@keyframes asteroid4Spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} 

.astro-asteroid5 {
    display: none;
    width: 26.25px;
    top: -40px;
    left: calc(((100vw - 1200px) / 2) + 100px);
}

@media (prefers-reduced-motion: no-preference) {
    .astro-asteroid5 {
        animation: asteroid5Movement infinite 80s linear;
    }
    .astro-asteroid5 > img {
        animation: asteroid5Spin infinite 2.5s linear;
    }
}


@keyframes asteroid5Movement {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(1500px);
    }
}

@keyframes asteroid5Spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.astro-asteroid6 {
    display: none;
    width: 14.5px;
    top: -100px;
    left: calc(((100vw - 1200px) / 2) + 260px);
}

@media (prefers-reduced-motion: no-preference) {
    .astro-asteroid6 {
        animation: asteroid6Movement infinite 8s linear;
        animation-delay: 2s;
    }
    .astro-asteroid6 > img {
        animation: asteroid6Spin infinite 0.5s linear;
    }
}

@keyframes asteroid6Movement {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(1500px);
    }
}

@keyframes asteroid6Spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.astro-astronaut {
    width: 453px;
    top: calc(226px + 299px);
    left: calc((100vw - 1200px) / 2);
}

@media (max-width: 1067px) {
    .astro-astronaut {
        width: 275px;
        top: 280px;
        left: 0;
        right: 20%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .astro-astronaut {
        animation: astroAstronautMovement infinite 28s ease-in-out;
        animation: astroAstronautMovement infinite 8s ease-in-out;
    }
}

@keyframes astroAstronautMovement {
    0%, 100% {
        transform: translate(0,15px);
    }
    50% {
        transform: translate(0,-15px);
    }
}

/*====================================
FOOTER
=====================================*/

footer {
    background-color: var(--primary-color-p100);
    color: #FFFFFF;
    padding-top: 60px;
    padding-bottom: 36px;
}

@media (max-width: 1067px) {
    footer {
        padding-top: 72px;
        padding-bottom: 36px;
    }
}

/* Top */

.footer-top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 1067px) {
    .footer-top {
        flex-direction: column;
        gap: 0;
    }
}

.footer-top > div:nth-child(2) {
    flex:1;
}


.footer-cta {
    text-align: right;
    padding-right: 36px;
}

@media (max-width: 1067px) {
    .footer-cta {
        padding-right: 0;
        margin-bottom: 60px;
    }
}

.footer-cta > a {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    padding: 16px 20px;
    border-radius: 500px;
    color: var(--primary-color-p1500);
    text-decoration: none;
}

.footer-cta > a > i {
    font-size: 12px;
    margin-left: 2px;
}

.footer-logo {}

@media (max-width: 1067px) {
    .footer-logo {
        margin-bottom: 12px;
    }
}


.footer-logo > a {
    font-family: 'Kodchasan', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    text-decoration: none;
    color: #FFFFFF;
}

.footer-logo > a > img {
    display: block;
    width: 30px;
}

.footer-logo > a > span {
    font-size: 24px;
    font-weight: 700;
    color: var(--primary-color-p1500);
    letter-spacing: -0.03em;
}

.footer-logo > a > span > span:last-child {
    font-weight: 200;
}

.footer-socials .social-icons > a {
    color: var(--primary-color-p1400);
}

.footer-socials .social-icons > a:hover {
    color: var(--primary-color-p1100);
}

/* Divider */

.footer-divider {
    height: 1px;
    width: 100%;
    background-color: var(--neutral-color-n300);
    margin-top: 36px;
    margin-bottom: 24px;
    display: block;
}

@media (max-width: 1067px) {
    .footer-divider {
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Bottom */

.footer-bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 1067px) {
    .footer-bottom {
        flex-direction: column;
    }
}

.footer-bottom > div:nth-child(1) {}

@media (max-width: 1067px) {
    .footer-bottom > div:nth-child(1) {
        margin-bottom: 60px;
    }
}


.footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 12px;
}

@media (max-width: 1067px) {
    .footer-nav {
        flex-direction: column;
    }
}

.footer-nav > li {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
}

.footer-nav > li > a {
    text-decoration: none;
    color: var(--neutral-color-n700);
    font-weight: 600;
}

.footer-nav > li > a:hover {
    color: var(--neutral-color-n700);
    transition: 0.25s;
    text-decoration: underline;
}

.footer-powered {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 12px;
}

@media (max-width: 1067px) {
    .footer-powered {
        flex-direction: column;
    }
}

.footer-powered > p {
    display: block;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    color: var(--neutral-color-n700);
    text-transform: uppercase;
    margin: 0;
}

.footer-powered > a {
    font-family: 'Kodchasan', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2.6px;
    background-color: var(--neutral-color-n100);
    padding: 6px 12px;
    border-radius: 500px;
    color: var(--neutral-color-n700);
    line-height: 1;
    font-size: 14px;
    text-decoration: none;
    transition: 0.15s;
}

.footer-powered > a:hover {
    background-color: var(--neutral-color-n200);
    transition: 0.25s;
    color: var(--primary-color-p1400);
}

.footer-powered > a > img {
    width: 18px;
    display: block;
    opacity: 0.55;
    transition: 0.15s;
}

.footer-powered > a:hover > img {
    opacity: 1;
    transition: 0.25s;
}

.footer-powered > a > span {
    font-weight: 700;
}

.footer-powered > a > span > span:last-child {
    font-weight: 200;
}


/*====================================
SECTION > MOCHI TOKEN
=====================================*/

.Section-Mochi-Token {
    padding-top: 120px;
    padding-bottom: 120px;
}

@media (max-width: 1067px) {
    .Section-Mochi-Token {
        padding-top: 96px;
        padding-bottom: 96px;
    }
}

.mochi-token-flexbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 72px;
    gap: 0;
}

@media (max-width: 1067px) {
    .mochi-token-flexbox {
        flex-direction: column;
        text-align: center;
    }
}

.mochi-token-flexbox-column1 {
    width: 58.5%;
    padding-left: 40px;
    padding-left: 0;
}

@media (max-width: 1067px) {
    .mochi-token-flexbox-column1 {
        width: 100%;
    }
}

.mochi-token-flexbox-column2 {
    width: initial;
    flex: 1;
    padding-left: 5%;
}

@media (max-width: 1067px) {
    .mochi-token-flexbox-column2 {
        padding-left: 0;
        order: -1;
        max-width: 240px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-bottom: 36px;
    }
}

.mochi-token-flexbox-column2 > img {
    width: 100%;
    max-width: 360px;
}

@media (max-width: 1067px) {
    .mochi-token-flexbox-column2 > img {
        max-width: 160px;
    }
}

.content-headingX {}

@media (max-width: 1067px) {
    .content-headingX {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

.content-headingX > h2 {
    font-size: 64px;
    line-height: 1.1;
    color: var(--primary-color-p200);
    font-weight: 600;
    margin: 0;
    display: block;
    margin-bottom: 24px;
}

@media (max-width: 1067px) {
    .content-headingX > h2 {
        font-size: 40px;
    }
}

.content-headingX > h2 > span {
    color: var(--primary-color-p800);
}

.content-headingX > p {
    margin: 0;
}

.content-headingX > p:nth-child(2) {
    font-size: 20px;
    line-height: 1.1;
    font-weight: 600;
    color: var(--neutral-color-n200);
    margin-bottom: 12px;
}

@media (max-width: 1067px) {
    .content-headingX > p:nth-child(2) {
        font-size: 20px;
    }
}

.content-headingX > p:nth-child(3) {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
    color: var(--neutral-color-n500);
    margin-bottom: 36px;
}

/*====================================
SECTION > MOCHI NFT
=====================================*/

.Section-Mochi-Nft {
    padding-top: 120px;
    padding-bottom: 120px;
}

@media (max-width: 1067px) {
    .Section-Mochi-Nft {
        padding-top: 96px;
        padding-bottom: 96px;
    }
}

.mochi-nft-flexbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 72px;
    gap: 0;
}

@media (max-width: 1067px) {
    .mochi-nft-flexbox {
        flex-direction: column;
    }
}

.mochi-nft-flexbox-column1 {
    width: 64.5%;
    width: 58.5%;
    padding-right: 40px;
    padding-right: 0;
}

@media (max-width: 1067px) {
    .mochi-nft-flexbox-column1 {
        text-align: center;
        width: 100%;
    }
}

.mochi-nft-flexbox-column2 {
    width: initial;
    flex: 1;
    order: -2;
    padding-top: 54px;
    padding-top: 0;
    padding-right: 5%;
}

@media (max-width: 1067px) {
    .mochi-nft-flexbox-column2 {
        padding-right: 0;
        max-width: 260px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 48px;
    }
}

.mochi-nft-flexbox-column2 > img {
    width: 100%;
    display: block;
}

@media (max-width: 1067px) {
    .mochi-nft-flexbox-column2 > img {
        /* max-width: 160px; */
    }
}

.Section-Mochi-Nft .content-headingX > h2 {
    color: var(--primary-color-p1500);
}

.Section-Mochi-Nft .content-headingX > p {
    color: var(--neutral-color-n900);
}

.Section-Mochi-Nft .content-headingX > p:nth-child(2) {
    max-width: 660px;
}

.Section-Mochi-Nft .content-headingX > p:nth-child(3) {
    color: var(--neutral-color-n700);
}

.Section-Mochi-Nft .content-grid-item {
    width: calc(50% - 8px);
}

@media (max-width: 1067px) {
    .Section-Mochi-Nft .content-grid-item {
        width: 100%;
    }
}

/*====================================
SECTION > MOCHI DAPP
=====================================*/

.Section-Mochi-Dapp {
    padding-top: 120px;
    padding-bottom: 120px;
    overflow: hidden;
}

@media (max-width: 1067px) {
    .Section-Mochi-Dapp {
        padding-top: 96px;
        padding-bottom: 96px;
    }
}

.mochi-dapp-flexbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 0;
}

@media (max-width: 1067px) {
    .mochi-dapp-flexbox {
        flex-direction: column;
    }
}

.mochi-dapp-flexbox-column1 {
    width: 58.5%;
    padding-left: 0;
}

@media (max-width: 1067px) {
    .mochi-dapp-flexbox-column1 {
        text-align: center;
        width: 100%;
    }
}

.mochi-dapp-flexbox-column2 {
    width: initial;
    flex: 1;
    padding-left: 5%;
}

@media (max-width: 1067px) {
    .mochi-dapp-flexbox-column2 {
        order: -1;
        margin-bottom: 48px;
        max-width: 90%;
        padding-left: 0;
    }
}

.mochi-dapp-flexbox-column2 > img {
    width: 240%;
    display: block;
}

@media (max-width: 1067px) {
    .mochi-dapp-flexbox-column2 > img {
        width: 180%;
    }
}

.Section-Mochi-Dapp .content-grid-item {
    flex-basis: calc(50% - 16px);
    border-color: #E0C9B8;
    box-shadow: 0px 2px 1px 0px #E0C9B8;
}

.Section-Mochi-Dapp .content-grid-item:last-child {
    flex: 1;
}


/*====================================
SECTION > NEXT
=====================================*/


.Section-Next {
    padding-top: 72px;
    padding-bottom: 72px;
    border-bottom: 1px solid #D1D1D1;
    background: var(--About-Fade, linear-gradient(180deg, #FAF9FA 46%, #EBEBEB 100%));
    text-align: center;
}

.Section-Next .content-headingX {
    margin-left: auto;
    margin-right: auto;
}

.Section-Next .content-headingX h2 {
    font-size: 40px;
    line-height: 1.1;
    font-weight: 600;
    color: var(--primary-color-p200);
    margin-bottom: 24px;
}

@media (max-width: 1067px) {
    .Section-Next .content-headingX h2 {
        font-size: 32px;
    }
}

.Section-Next .content-headingX p {
    display: block;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 48px;
    display: block;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    color: var(--neutral-color-2400) !important;
    opacity: 0.5;
}

@media (max-width: 1067px) {
    .Section-Next .content-headingX p {
        font-size: 20px;
    }
}

.Section-Next .content-grid .content-grid-item {
    width: 50%;
}

.Section-Next .content-grid-item-heading

.Section-Next .content-grid-item-heading h3 {
    font-size: 28px;
    line-height: 
}



.Section-Next .about-item-heading > img {
    margin-right: 0;
}

.Section-Another {
    height: 700px;
    background-color: #FFFFFF;
    display: none;
}


.Section-Next .about-item-heading h3 {
    font-size: 24px;
    line-height: 1.1;
    color: var(--primary-color-p300);
}

@media (max-width: 1067px) {
    .Section-Next .about-item-heading h3 {
        font-size: 20px;
    }
}



.svgContainer {
    position: relative;
}

.svgContainer > svg {
    width: 100%;
}


.Section-About .svgContainer > svg {
}

@media (max-width: 767px) {
    .Section-About .svgContainer > svg {
        height: 40px !important;
        transform: translateY(-1px);
        z-index: 9;
        position: relative;
    }
}


.Section-Next .about-item-heading {}

@media (max-width: 1067px) {
    .Section-Next .about-item-heading {
        justify-content: center;
        align-items: center;
    }
}

.Section-About .about-item {}

@media (max-width: 1067px) {
    .Section-About .about-item {
        text-align: center;
        padding: 48px 24px;
        margin-bottom: 24px;
    }
    .Section-About .about-item:last-child {
        margin-bottom: 0 !important;
    }
}


/* =====================================
MOCHI M SYMBOL
===================================== */

.mochi-m-symbol {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: var(--primary-color-p800);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    font-family: 'Kodchasan', sans-serif;
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
    color: #FFFFFF;
    display: none;
}

@media (max-width: 1067px) {
    .mochi-m-symbol {
        height: 24px;
        width: 24px;
        font-size: 18px;
        display: none;
    }
}

.mochi-m-symbol span {
    display: block;
    transform: translateY(-2px);
}

@media (max-width: 1067px) {
    .mochi-m-symbol span {
        transform: translateY(-1px);
    }
}

.Section-About .mochi-m-symbol {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
}


.mochi-m-symbol-3d {
    width: 48px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
}

.mochi-m-symbol-3d > img {
    display: block;
    width: 100%;
    display: none;
}


/* mISC */

.stats-heading-mobile {
    display: none;
}

.stats-heading-desktop {
    display: block;
}


@media (max-width: 1067px) {
    .stats-heading-mobile {
        display: block;
    }
    
    .stats-heading-desktop {
        display: none;;
    }
}

.about-heading-mobile {
    display: none !important;
}

.about-heading-desktop {
    display: block !important;
}

@media (max-width: 1067px) {
    .about-heading-mobile {
        display: block !important;
    }
    
    .about-heading-desktop {
        display: none !important;
    }
}


.next-heading-desktop {
    display: block !important;
}

.next-heading-mobile {
    display: none !important;
}

@media (max-width: 1067px) {
    .next-heading-desktop {
        display: none !important;
    }
    
    .next-heading-mobile {
        display: block !important;
    }
}


.section-about-card-heading-mobile {
    display: none !important;
}

.section-about-card-heading-desktop {
    display: block !important;
}

@media (max-width: 1067px) {
    .section-about-card-heading-mobile {
        display: block !important;
    }
    
    .section-about-card-heading-desktop {
        display: none !important;
    }
    
}

.hero-subtitle-mobile {
    display: none !important;
}

.hero-subtitle-desktop {
    display: inline !important;
}

@media (max-width: 1067px) {
    .hero-subtitle-mobile {
        display: inline !important;
    }
    
    .hero-subtitle-desktop {
        display: none !important;
    }
    
}





/*====================================
DARKMODE THEME
=====================================*/


.App-Darkmode .site-nav-desktop-main {
    background-color: #2C121C;
}

.App-Darkmode .Section-Hero {
    background: linear-gradient(180deg, #230B15 7%, #260B19 53.5%, #2B0F20 100%);
}

.App-Darkmode .Section-Hero h1 {
    color: var(--primary-color-p1400);
}

.App-Darkmode .Section-Hero h1 span {
    background: linear-gradient(90deg, #BD0A75 62.04%, #F6098B 88.42%, #FE2EA0 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.App-Darkmode .Section-Hero .hero-subtitle-desktop {
    color: #CFA1BE;
}

.App-Darkmode .Section-Hero .social-icons {
    color: var(--neutral-color-n800);
}

.App-Darkmode .Section-Hero  .social-icons > a {
    color: var(--neutral-color-n800);
}

.App-Darkmode .site-nav-desktop-item > a {
    color: var(--primary-color-p1400);
}

.App-Darkmode .site-nav-desktop-main > li:after {
    background-color: #37222A;
}

.App-Darkmode .site-nav-desktop-logo > a {
    color: var(--primary-color-p1400);
}

.App-Darkmode .Section-Stats {
    /* background: linear-gradient(175.5deg, #1b0a13 0%, #000000 42.72%, #000000 84.05%, #000000 98.55%);
    background: linear-gradient(175.3deg, #1c0a14 0%, #000000 42.72%, #2A0C19 84.05%, #2E0E1B 98.55%); */
    /* background: linear-gradient(var(--gradient-angle), #1c0a14 0%, #000000 42.72%, #2A0C19 84.05%, #2E0E1B 98.55%); */
    /* background: linear-gradient(var(--gradient-angle), #1b0a13 0%, #000000 35.72%, #000000 60%, #2A0C19 84.05%, #2E0E1B 98.55%); */
    background: linear-gradient(var(--gradient-angle), #1b0a13 0%, #000000 35%);
}

.Hero-Divider--LightMode {}

.App-Darkmode .Hero-Divider--LightMode {
    display: none !important;
}

.Hero-Divider--DarkMode {
    display: none;
}

.App-Darkmode .Hero-Divider--DarkMode {
    display: block;
}

.App-Darkmode .Section-Stats .svgContainer svg path {
    fill: #0F000A !important;
}

.App-Darkmode .Section-Stats .layout-container > h2 {
    color: var(--primary-color-p1400);
}

.App-Darkmode .stats-overview-item > div:nth-child(1) {
    color: var(--primary-color-p1100);
}

.App-Darkmode  .stats-overview-item > div:nth-child(2) {
    color: #CFA1BE;
}

.App-Darkmode .Section-About .svgContainer svg {
    fill: #2D0D1B;
}

.App-Darkmode .Section-About {
    background: initial;
    background-color: #0F000A;
}

.App-Darkmode .Section-About .section-bg-layer.over {
    background: rgba(0,0,0,0);
}

.App-Darkmode .Section-About .svgContainer svg > path {
    fill: #2D0D1B !important;
}

.App-Darkmode .Section-About .about-header > h2 {
    color: var(--primary-color-p1400);
}

.App-Darkmode .Section-About .about-item {
    border-radius: 32px;
    border: 1px solid rgba(51, 31, 41, 0.60);
    background: linear-gradient(90deg, rgba(51, 31, 41, 0.50) 0%, rgba(33, 15, 24, 0.50) 100%);
    box-shadow: 0px 3px 1px 0px rgba(51, 31, 41, 0.60);
}

.App-Darkmode .about-item p {
    color: var(--neutral-color-n700);
}

.App-Darkmode .about-item-heading h3 {
    color: var(--primary-color-p1400);
}

.App-Darkmode .btn-alt {
    background-color: var(--neutral-color-n100);
    color: var(--neutral-color-n700);
}







/* MISC */

.mochi-nav-button {
    width: 170px;
}

.mochi-nav-button--nav {
    width: 160px;
    height: 48px;
}

.mochi-nav-button--hero {
    width: 200px;
    height: 56px;
}

span.btn-reveal {
    position: relative;
    height: 100%;
    width: 100%;
    display: inline-block;
}

span.btn-reveal i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.mochi-nav-button > span.btn-reveal > span:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 1;
    transition: 0.35s;
}


.mochi-nav-button > span.btn-reveal > span:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: 0.05s;
}

.mochi-nav-button:hover > span.btn-reveal > span:nth-child(1) {
    opacity: 0;
    transition: 0.05s;
}

.mochi-nav-button:hover > span.btn-reveal > span:nth-child(2) {
    opacity: 1;
    transition: 0.35s;
}



.content-grid-item-heading img {
    display: none;
}


.akljebg {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    font-style: italic;
    color: var(--neutral-color-n600);
    font-size: 10px;
    line-height: 14px;
}

@media (max-width: 567px) {
    .akljebg {
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
        padding-bottom: 60px;
    }
}


.Section-Hero a.btn {
    z-index: 999;
    position: relative;
}

@media (max-width: 1067px) {
    .Section-Hero a.btn {
        z-index: initial;
        position: initial;
    }
}


@media (max-width: 1067px) {
    .content-grid-item-body p {
        /* text-align: center; */
    }
}


.content-grid-item--blank {
    visibility: hidden;
}

@media (max-width: 1067px) {
    .content-grid-item--blank {
        display: none;
    }
}


/* PARTNER LOGOS */

.partner-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 48px;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1067px) {
    .partner-logos {
        flex-direction: column;
        gap: 32px;
        padding-top: 24px;
    }
}

.partner-logos > div {
    max-width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.partner-logos > div > a {
    display: block;
}

.partner-logos > div > a > img {
    display: block;
    width: 100%;
}

.partner-logos > div:nth-child(1) > a > img {
    max-width: 200px;
}

.partner-logos > div:nth-child(2) > a > img {
    max-width: 150px;
}

.partner-logos > div:nth-child(3) > a > img {
    max-width: 150px;
}

.partner-logos > div:nth-child(4) > a > img {
    max-width: 200px;
}


/*====================================
PARTNERS
=====================================*/

.partners {}

.partners-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.partners-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
}

@media (max-width: 667px) {
    .partners-row {
        gap: 8px;
    }
}

@media (max-width: 467px) {
    .partners-row {
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
    }
}

.partners-item {
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #FFEBF8;
    background-color: #FFFFFF;
    border: 1px solid #FFD1F0;
    border-radius: 8px;
}

@media (max-width: 667px) {
    .partners-item {
        flex-basis: calc(50% - 16px);
    }
}

@media (max-width: 467px) {
    .partners-item {
        flex-basis: 100%;
    }
}

.partners-item > img {
    height: 24px;
    width: auto !important;
    display: block;
}


@media (max-width: 467px) {
    .partners-item.hidden {
        display: none;
    }
}

button.showPartners {
    display: none;
    background: rgba(0,0,0,0);
    background-color: none;
    border: none;
    outline: none;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    opacity: 0.65;
    color: #2e0015;
}

button.showPartners > i {
    margin-left: 8px;
    font-size: 12px;
}

.partners-container.expanded > button > i {
    transform: rotate(180deg);
}

button.showPartners:hover {
    cursor: pointer;
}

@media (max-width: 467px) {
    button.showPartners {
        display: flex;
    }
}